import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import logo from "./logo.svg"
import "./App.css"
import { ExportForm } from "./Forms/ExportForm"
import { ImportForm } from "./Forms/ImportForm"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { CaseFreeTextForm } from "./Forms/CaseSpecific/FreeTextForm"

function App() {
  return (
    <div className='App'>
      <GoogleReCaptchaProvider reCaptchaKey='6Lcb_espAAAAAJ4rvZIobov7-ZYrVtEWy59E8jxb'>
        <Router>
          <Routes>
            <Route path='/export/:id' element={<ExportForm />} />
            <Route path='/import/:id' element={<ImportForm />} />
            <Route path='/case/:id' element={<CaseFreeTextForm />} />
            {/* Add more routes for other forms if needed */}
            <Route
              path='*'
              element={
                <div>
                  Process cases with <a href='https://digicust.com'>Digicust</a>
                </div>
              }
            />
          </Routes>
        </Router>
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default App
