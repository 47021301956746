import React, { useEffect, useState } from "react"
import {
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Select,
  Upload,
  message,
  Row,
  Col,
  Radio,
  Steps,
  Space,
} from "antd"
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons"
import Logo from "../digicust_logo.png"
import { RcFile } from "antd/es/upload"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"
import { useParams } from "react-router-dom"
import DCTooltip from "../components/Tooltip"

const { Option } = Select
const { TextArea } = Input
const { Step } = Steps

export const ExportForm = () => {
  const { id } = useParams()

  const [form] = Form.useForm()
  const [current, setCurrent] = useState(0)
  const [type, setType] = useState("private")
  const [differingRecipient, setDifferingRecipient] = useState(false)
  const [differingPlaceOfPresentation, setDifferingPlaceOfPresentation] =
    useState(false)
  const [captchaToken, setCaptchaToken] = useState(null)

  const [fileList, setFileList] = useState<RcFile[]>([])

  const [widgetConfiguration, setWidgetConfiguration] = useState<any>({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.digicust.com/generic/api/website/widget/${id}`
        )
        const data = response.data
        setWidgetConfiguration(data)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    fetchData()
  }, [id])

  const handleFinish = async (values: any) => {
    console.log("Form values:", values)
    // if (!captchaToken) {
    //   message.error("Please complete the CAPTCHA")
    //   return
    // }

    // Create a FormData object
    const formData = new FormData()

    // Append files to the FormData object
    fileList.forEach((file) => {
      formData.append("files[]", file)
    })

    // Append other form values to the FormData object
    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key])) {
        values[key].forEach((item: any, index: number) => {
          Object.keys(item).forEach((subKey) => {
            formData.append(`${key}[${index}][${subKey}]`, item[subKey])
          })
        })
      } else {
        formData.append(key, values[key])
      }
    })

    if (captchaToken) {
      formData.append("g-recaptcha-response", captchaToken)
    }

    formData.append("id", id || "")

    // Simulate form submission
    try {
      // Your form submission logic here
      console.log("Uploading files and form data...")
      await axios.post(
        "https://api.digicust.com/generic/api/website/widget/upload",
        formData
      )

      message.success("Form submitted successfully!")
    } catch (error) {
      console.error("Failed to submit form:", error)
      message.error("Failed to submit the form!")
    }
  }

  const handleFinishFailed = (errorInfo: any) => {
    console.log("Form failed:", errorInfo)
    message.error(errorInfo?.errorFields?.[0]?.errors?.[0])
  }

  const steps = ["Ihre Details", "Sendung", "Warenpositionen & Bestätigung"]

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Radio.Group
          style={{ marginBottom: "20px" }}
          value={type}
          onChange={(e) => setType(e.target.value)}>
          <Radio.Button value='private'>Privat</Radio.Button>
          <Radio.Button value='commercial'>Für Unternehmen</Radio.Button>
        </Radio.Group>
        <span style={{ marginTop: "-20px", marginLeft: "20px" }}>
          Ausfuhranmeldung in 3 Schritten beantragen
        </span>
      </div>
      <Steps onChange={(value) => setCurrent(value)} current={current}>
        {steps.map((title) => (
          <Step key={title} title={title} />
        ))}
      </Steps>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        style={{ marginTop: "20px" }}>
        <div style={{ display: current === 0 ? "block" : "none" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='Rechnungen, Packlisten, Lieferscheine etc.'
                name='invoices'
                rules={[
                  {
                    required: true,
                    message: "Bitte laden Sie die Rechnungen hoch",
                  },
                ]}>
                <Upload
                  multiple={true}
                  accept='.pdf'
                  beforeUpload={(file, fileList) => {
                    setFileList(fileList)
                    return false // Prevent automatic upload
                  }}>
                  <Button icon={<UploadOutlined />}>Hochladen</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label='Ihr Name'
                name='name'
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie Ihren Namen ein",
                  },
                ]}>
                <Input placeholder='Max Mustermann' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {type === "commercial" && (
              <>
                <Col span={12}>
                  <Form.Item
                    label='Ihr Unternehmen'
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie den Unternehmensnamen ein",
                      },
                    ]}
                    name='company'>
                    <Input placeholder='Deutsche Mustermann GmbH' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie Ihre EORI-Nummer ein",
                      },
                    ]}
                    label='Ihre EORI-Nummer'
                    name='eori'>
                    <Input placeholder='DE12345678912345' />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='Ihre E-Mail'
                name='email'
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Bitte geben Sie eine gültige Email-Adresse ein.",
                  },
                ]}>
                <Input placeholder='max@mustermanngmbh.de' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Ihre Telefonnummer' name='phone'>
                <Input placeholder='+49 123 123 123' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Darf nicht leer sein.",
                  },
                ]}
                label='Ihre Adresse'
                name='address'>
                <TextArea
                  placeholder='Mustermannstraße 1, 99999 Musterstadt, Deutschland'
                  rows={3}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ marginBottom: "-1px" }}>
                <Checkbox
                  checked={differingRecipient}
                  onChange={(e) => setDifferingRecipient(e.target.checked)}>
                  Empfänger abweichend von Rechnung{" "}
                  <DCTooltip text='Im Normalfall entnehmen wir die Empfängeradresse von der Handelsrechnung die Sie diesem Formular anhängen. Sollte die Adresse jedoch abweichen oder auf den angehängten Dokumenten nicht vorhanden sein, können Sie hier eine andere Adresse angeben.' />
                </Checkbox>
              </Form.Item>
              {differingRecipient && (
                <>
                  <Form.Item
                    name='recipientName'
                    style={{ marginBottom: "5px" }}>
                    <TextArea rows={1} placeholder='Empfängername' />
                  </Form.Item>
                  <Form.Item name='recipientAddress'>
                    <TextArea
                      rows={2}
                      placeholder='Strasse, Hausnummer, Stadt, Postleitzahl'
                    />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
        </div>

        <div style={{ display: current === 1 ? "block" : "none" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<>Beförderung</>}
                name='transportType'
                rules={[
                  {
                    required: true,
                    message: "Bitte wählen Sie die Art der Beförderung aus",
                  },
                ]}>
                <Select placeholder='Art der Beförderung'>
                  <Option value='land'>Landtransport (LKW)</Option>
                  <Option value='sea'>Seefracht (Schiff)</Option>
                  <Option value='air'>Luftfracht (Flugzeug)</Option>
                  {/* Add more options if necessary */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Ausgangszollstelle'
                name='exitCustomsOffice'
                rules={[
                  {
                    required: true,
                    message: "Bitte wählen Sie die Ausgangszollstelle aus",
                  },
                ]}>
                <Input placeholder='DE004600' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ marginBottom: "-1px" }}>
                <Checkbox
                  checked={differingPlaceOfPresentation}
                  onChange={(e) =>
                    setDifferingPlaceOfPresentation(e.target.checked)
                  }>
                  Gestellungsort abweichend von Ihrer Adresse
                </Checkbox>
              </Form.Item>
              {differingPlaceOfPresentation && (
                <>
                  <Form.Item name='placeOfPresentation'>
                    <TextArea
                      rows={2}
                      placeholder='Strasse, Hausnummer, Stadt, Postleitzahl'
                    />
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                label='Gestellungsdatum'
                name='presentationDate'
                rules={[
                  {
                    required: true,
                    message: "Bitte wählen Sie das Gestellungsdatum",
                  },
                ]}>
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ display: current === 2 ? "block" : "none" }}>
          <Form.Item
            label='Artikel (nur wenn Tarifnummer oder Beschreibung auf hochgeladenen Dokumenten fehlt)'
            name='items'>
            <Form.List name='items'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row gutter={16} key={key}>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, "articleNumber"]}
                          rules={[
                            {
                              required: true,
                              message: "Artikelnummer erforderlich",
                            },
                          ]}>
                          <Input placeholder='Artikelnummer' />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          tooltip={"test"}
                          name={[name, "tariffNumber"]}>
                          <Input placeholder='Tarifnummer' />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item {...restField} name={[name, "description"]}>
                          <Input placeholder='Beschreibung/Verwendungszweck' />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <MinusCircleOutlined
                          style={{ margin: "8px 0" }}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}>
                      Artikel hinzufügen
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            label='Packstückinformation (wenn nicht auf hochgeladenen Dokumenten)'
            name='packaging'>
            <TextArea
              rows={2}
              placeholder='Bitte geben Sie an, wieviele Packstücke benötigt werden um Ihre Waren zu versenden und wie diese einzelnen Packstücke verpackt sind. (z.B. 2 Paletten, 3 Kartons und 4 Artikel unverpackt)'
            />
          </Form.Item>
          <p>{widgetConfiguration?.terms}</p>
          <Form.Item
            style={{ marginBottom: "5px" }}
            name='confirmation'
            valuePropName='checked'
            rules={[
              {
                validator: async (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("AGB und Zollvollmacht bestätigen")
                      ),
              },
            ]}>
            <Checkbox>
              Ich habe die AGB gelesen und bestätige die Zollvollmacht.
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <GoogleReCaptcha
              onVerify={(value) => setCaptchaToken(value as any)}
            />
          </Form.Item>
        </div>

        <Space direction='horizontal'>
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Zurück
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type='primary' onClick={() => next()}>
              Weiter
            </Button>
          )}
          {current === steps.length - 1 && (
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type='primary' htmlType='submit'>
                Ausfuhranmeldung verbindlich anfragen
              </Button>
            </Form.Item>
          )}
        </Space>
      </Form>
      <div
        style={{
          marginTop: "-20px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}>
        Powered by{" "}
        <a target='_blank' href='https://digicust.com'>
          <img
            style={{
              marginTop: "10px",
              width: "100px",
              marginLeft: "10px",
            }}
            src={Logo}
            alt='Digicust Logo'
          />
        </a>
      </div>
    </div>
  )
}
