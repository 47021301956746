import React, { useCallback, useEffect, useState } from "react"
import {
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Select,
  Upload,
  message,
  Row,
  Col,
  Radio,
  Steps,
  Space,
} from "antd"
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons"
import Logo from "../../digicust_logo.png"
import { RcFile } from "antd/es/upload"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"
import { useParams, useSearchParams } from "react-router-dom"
import DCTooltip from "../../components/Tooltip"

const { Option } = Select
const { TextArea } = Input
const { Step } = Steps

export const CaseFreeTextForm = () => {
  const { id } = useParams()

  const [form] = Form.useForm()

  const [captchaToken, setCaptchaToken] = useState<string>("")

  const [fileList, setFileList] = useState<RcFile[]>([])

  const [searchParams, setSearchParams] = useSearchParams()

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token)
  }, [])

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const email = searchParams.get("email")
  const placeholder = searchParams.get("placeholder")
  const prompt = searchParams.get("prompt")
  const reference = searchParams.get("reference")
  const initialValue = searchParams.get("initialValue")
  const customerId = searchParams.get("customerId")
  const projectId = searchParams.get("projectId")

  const allowFileUpload = searchParams.get("allowFileUpload")

  const handleFinish = async (values: any) => {
    console.log("Form values:", values)
    // if (!captchaToken) {
    //   message.error("Please complete the CAPTCHA")
    //   return
    // }

    // Create a FormData object
    const formData = new FormData()

    // Append files to the FormData object
    fileList.forEach((file) => {
      formData.append("files[]", file)
    })

    // Append other form values to the FormData object
    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key])) {
        values[key].forEach((item: any, index: number) => {
          Object.keys(item).forEach((subKey) => {
            formData.append(`${key}[${index}][${subKey}]`, item[subKey])
          })
        })
      } else {
        formData.append(key, values[key])
      }
    })

    if (captchaToken) {
      formData.append("g-recaptcha-response", captchaToken)
    }

    formData.append("id", id || "")
    formData.append("email", email || "")
    formData.append("customerId", customerId || "")
    formData.append("projectId", projectId || "")

    // Simulate form submission
    try {
      // Your form submission logic here
      console.log("Uploading files and form data...")
      await axios.post(
        "https://api.digicust.com/generic/api/website/widget/case/free-text-form",
        formData
      )

      message.success("Form submitted successfully!")
    } catch (error) {
      console.error("Failed to submit form:", error)
      message.error("Failed to submit the form!")
    }
    setRefreshReCaptcha((r) => !r)
  }

  const handleFinishFailed = (errorInfo: any) => {
    console.log("Form failed:", errorInfo)
    message.error(errorInfo?.errorFields?.[0]?.errors?.[0])
  }

  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginBottom: "-10px" }}>
          Add information to '{reference}'
        </span>
      </div>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        style={{ marginTop: "20px" }}>
        <div>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Must not be empty.",
                  },
                ]}
                label={prompt}
                initialValue={initialValue}
                name='body'>
                <TextArea placeholder={placeholder || "Body"} rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='Additional documents (optional)'
                name='invoices'
                rules={[]}>
                <Upload
                  multiple={true}
                  accept='.pdf'
                  beforeUpload={(file, fileList) => {
                    setFileList(fileList)
                    return false // Prevent automatic upload
                  }}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label='Your email'
                name='email'
                initialValue={email}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please submit your email.",
                  },
                ]}>
                <Input placeholder='max@mustermanngmbh.de' />
              </Form.Item>
            </Col> */}
          </Row>
        </div>
        <Form.Item key={"fixedCaptchaHost"} style={{ textAlign: "center" }}>
          <GoogleReCaptcha
            key={"fixedCaptcha"}
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </Form.Item>
        <Space direction='horizontal'>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type='primary' htmlType='submit'>
              Add information as {email}
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <div
        style={{
          marginTop: "-20px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}>
        Powered by{" "}
        <a target='_blank' href='https://digicust.com'>
          <img
            style={{
              marginTop: "10px",
              width: "100px",
              marginLeft: "10px",
            }}
            src={Logo}
            alt='Digicust Logo'
          />
        </a>
      </div>
    </div>
  )
}
